// src/Location.js
import React, { useState } from "react";
import Map from "./Map";

const Location = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  // Handle location change from the map
  const handleLocationChange = (newLocation) => {
    setLocation({
      latitude: newLocation.lat,
      longitude: newLocation.lng,
    });
  };
  console.log(navigator);
  return (
    <div>
      <button onClick={getLocation}>Get Current Location</button>
      {location.latitude && location.longitude && (
        <div>
          <h3>Current Location:</h3>
          <p>Latitude: {location.latitude}</p>
          <p>Longitude: {location.longitude}</p>
          <Map location={location} onLocationChange={handleLocationChange} />
          <div>
            <hr />
            Details:
            <br /> appName: {navigator?.appName}
            <br /> appVersion: {navigator?.appVersion}
            <br /> appVersion: {navigator?.appVersion}
            <br /> platform: {navigator?.platform}
            <br /> language: {navigator?.language}
          </div>
        </div>
      )}
      {error && <p>Error: {error}</p>}
    </div>
  );
};

export default Location;
