//AIzaSyAVJY3SEZjqkykIv6sQ-3x-3hi3xUG_hjo
// src/Map.js
import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const defaultCenter = {
  lat: -3.745,
  lng: -38.523,
};

const Map = ({ location, onLocationChange }) => {
  const [currentPosition, setCurrentPosition] = useState(defaultCenter);

  useEffect(() => {
    if (location.latitude && location.longitude) {
      setCurrentPosition({
        lat: location.latitude,
        lng: location.longitude,
      });
    }
  }, [location]);

  const onMarkerDragEnd = (e) => {
    const newPosition = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    setCurrentPosition(newPosition);
    onLocationChange(newPosition);
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyBdCAJUZAEpFLhIsrDCHHNO7vBddeYaAsM">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={currentPosition}
        zoom={15}
      >
        <Marker
          position={currentPosition}
          draggable={true}
          onDragEnd={onMarkerDragEnd}
        />
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
